.hero-about {
    width: 100%;
    padding: 58px 0 145px 0;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    min-height: 102px;

    .overlay{
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        background-color: #fff;
        opacity: 0.4;
        top: 0;
        bottom: 0;
        left: 0;
        z-index: 1;
        right: 0;
        margin: auto;
    }
    .hero-title {
        font-family: Poppins;
        font-style: normal;
        font-weight: 600;
        font-size: 40px;
        line-height: 52px;
        text-align: center;
        z-index: 2;
        color: #282F38;
        position: relative;
    }
    .bread{
        position: relative;
    }
}