.project-page{
    .inner-project{
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        .column-one{
            width: 21%;
            .open-blocks {
                background: #F6F6F7;
                border-radius: 10px;
                overflow: hidden;
            }

            .open-block {
                border-bottom: 1px solid rgb(224, 224, 224);

                &:last-child {
                    border-bottom: none;
                }

                .opener {
                    width: 100%;

                    .button {
                        font-family: Poppins;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 16px;
                        line-height: 24px;
                        width: 100%;
                        text-align: center;
                        color: #282F38;
                        display: block;
                        padding: 13px 0;
                        border: none;
                        cursor: pointer;
                        &.active {
                            color: #fff;
                            background: #3987F3;
                            border-radius: 10px;
                        }
                    }
                    .multimenu{
                        li{
                            a{
                                font-family: Poppins;
                                font-style: normal;
                                font-weight: normal;
                                line-height: 24px;
                                width: 100%;
                                text-align: center;
                                color: #282F38;
                                display: block;
                                padding: 13px 0;
                                border: none;
                                cursor: pointer;
                                border-bottom: 1px solid #e0e0e0;
                            }
                            .first-step{
                                font-size: 15px;
                                &.active{
                                    background-color: rgb(127, 174, 240);
                                    color: #fff;
                                }
                            }
                            .second-step{
                                font-size: 14px;
                                &.active{
                                    background-color: rgb(158, 193, 243);
                                    color: #fff;
                                }
                            }
                        }
                    }
                }
            }

            .tomchi{
                width: 100%;
                    img{
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                    }
            }

            .categories{
                width: 100%;
                border-radius: 10px;
                overflow: hidden;
                background: #F6F6F7;
                .cate-ul{
                    width: 100%;
                    margin: 0;
                    .cate-btn{
                        border: none;
                            border-bottom: 1px solid rgb(224, 224, 224);
                            width: 100%;
                            background-color: transparent;
                            padding: 10px 0;
                            font-family: Poppins;
                            font-style: normal;
                            font-weight: normal;
                            font-size: 16px;
                            line-height: 24px;
                            width: 100%;
                            color: #282F38;
                            display: block;
                            cursor: pointer;
                            &.active {
                                color: #fff;
                                background: #3987F3;
                                border-radius: 10px;
                            }
                            &:last-child{
                                border-bottom: none;
                            }
                        }
                }
            }
        }


        .column-two{
            .section-holder {
                border-bottom: 1px solid #ccc;
                font-family: Poppins;
                font-style: normal;
                font-weight: 600;
                font-size: 18px;
                line-height: 22px;
                color: #2D2D2D;
                margin-bottom: 24px;
                position: relative;
                padding-left: 41px;
                padding-bottom: 24px;
                &::before {
                    position: absolute;
                    content: '';
                    width: 25px;
                    height: 7px;
                    background: #3987F3;
                    border-radius: 10px;
                    left: 0;
                    top: 10px;
                }
            }
            width: 56%;
            .project-card{
                .section-card{
                    margin-bottom: 24px;
                }
            }

            .projects{
                .matrix-module{
                    .donr-cards{
                        .matrix-container{
                            .matrix-filter{
                                display: flex;
                                border-left: 1px solid #e0e0e0;
                                a{
                                    font-family: Poppins;
                                    font-style: normal;
                                    font-weight: normal;
                                    font-size: 16px;
                                    line-height: 24px;
                                    width: 100%;
                                    text-align: center;
                                    color: #282F38;
                                    display: block;
                                    cursor: pointer;
                                    border: none;
                                    background: #f00;
                                    .menu-item {
                                        text-align: center;
                                        padding: 10px 7px;
                                        display: block;
                                        width: 100%;
                                        white-space: nowrap;
                                        border: none;
                                        border-right: 1px solid #e0e0e0;
                                        &:hover, &.active{
                                            background: #3987F3;
                                            color: #fff;
                                        }
                                    }
                                }

                            }
                            .matrix-table{
                                overflow-x: auto; // Gorizontal scroll hosil qilish
                                overflow-y: auto;
                                width: 100%;
                                height: 90%;
                                table{
                                    width: 100%;
                                    border-collapse: collapse;
                                    tr{
                                        td, th{
                                            border: 1px solid #575353;
                                            font-family: Arial;
                                            padding: 10px;
                                            text-align: left;
                                            //min-width: 100px;
                                            max-width: 300px; // Ustunlarning maksimal kengligini belgilash
                                            word-break: break-word;
                                            overflow-wrap: break-word;
                                        }
                                    }
                                    .subtotal{
                                        tr{
                                            background: #BDD6EE;
                                        }
                                    }
                                    thead{
                                        position: sticky;
                                        top: 0;
                                        background-color: white;
                                        z-index: 10;
                                        tr{
                                            background: #3987F3;
                                            color: #fff;
                                            th{
                                                padding: 10px 3px;
                                                text-align: center;
                                            }
                                        }
                                    }
                                    tbody{
                                        tr{
                                            td,th{
                                                padding: 5px;
                                                .td-text{
                                                    display: -webkit-box;
                                                    -webkit-line-clamp: 8; /* Matn 3 qatordan oshsa kesiladi */
                                                    -webkit-box-orient: vertical;
                                                    overflow: hidden;
                                                    text-overflow: ellipsis;
                                                    white-space: normal;
                                                    cursor: pointer;
                                                }
                                            }
                                        }
                                    }
                                    tfoot{

                                    }
                                }
                            }
                            .closer{
                                display: none;
                            }
                            &.full-page{
                                position: fixed;
                                top: 0;
                                left: 0;
                                height: 100%;
                                width: 100%;
                                z-index: 99;
                                background: #fff;
                                .closer{
                                    display: flex;
                                    align-items: center;
                                    justify-content: flex-end;
                                    height: 40px;
                                    .close-info{
                                        margin-right: 30px;
                                        display: flex;
                                        align-items: center;
                                        p{
                                            color: red;
                                            font-weight: 700;
                                            font-size: 16px;
                                        }
                                    }
                                    .close-btn{
                                        display: flex;
                                        height: 30px;
                                        background: none;
                                        font-weight: 700;
                                        font-size: 30px;
                                        margin-right: 20px;
                                        margin-bottom: 5px;
                                        border: none;
                                        transition: transform 0.3s ease;
                                        transform-origin: center;
                                        justify-content: center;
                                        align-items: center;
                                        &:hover{
                                            transform: rotate(180deg);
                                        }
                                    }

                                }
                                .full{
                                    display: none;
                                }
                                .matrix-table{
                                   table{
                                       font-size: 14px;
                                   }
                                }
                            }
                            &.normal{
                                .full{
                                    button{
                                        display: block;
                                        width: 100%;
                                        margin-bottom: 10px;
                                        border: 1px solid #3681E8;
                                        padding: 5px;
                                        font-weight: 700;
                                        background: #fff;
                                        &:hover{
                                            background: #3681e8;
                                            color: #fff;
                                        }
                                    }
                                }
                                .matrix-table{
                                    table{
                                        colgroup{
                                            col:nth-child(2){
                                                width: 40% !important;
                                            }
                                            col:nth-child(3),
                                            col:nth-child(5),
                                            col:nth-child(6),
                                            col:nth-child(7),
                                            col:nth-child(8),
                                            col:nth-child(10),
                                            col:nth-child(11),
                                            col:nth-child(12),
                                            col:nth-child(12),
                                            col:nth-child(13),
                                            col:nth-child(14){
                                                display: none;
                                            }
                                        }
                                        thead{
                                            th:nth-child(3),
                                            th:nth-child(5),
                                            th:nth-child(6),
                                            th:nth-child(7),
                                            th:nth-child(8),
                                            th:nth-child(10),
                                            th:nth-child(11),
                                            th:nth-child(12),
                                            th:nth-child(12),
                                            th:nth-child(13),
                                            th:nth-child(14){
                                                display: none;
                                            }
                                        }
                                        tbody.body{
                                            td:nth-child(3),
                                            td:nth-child(5),
                                            td:nth-child(6),
                                            td:nth-child(7),
                                            td:nth-child(8),
                                            td:nth-child(10),
                                            td:nth-child(11),
                                            td:nth-child(12),
                                            td:nth-child(12),
                                            td:nth-child(13),
                                            td:nth-child(14){
                                                display: none;
                                            }
                                        }
                                        .subtotal{
                                            td:nth-child(4),
                                            td:nth-child(5),
                                            td:nth-child(6),
                                            td:nth-child(7),
                                            td:nth-child(8){
                                                display: none;
                                            }
                                        }

                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .column-thr {
            width: 18%;
        }
    }
}

#cate-name{
    color: #000;
    margin: 30px 0 10px 0;
    font-size: 20px;
}
@keyframes shake {
    0% { transform: translateX(0); }
    25% { transform: translateX(-2px); }
    50% { transform: translateX(2px); }
    75% { transform: translateX(-2px); }
    100% { transform: translateX(0); }
}