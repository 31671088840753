.header{
    z-index: 15;
    position: relative;
    .test-mode{
       
        padding-left: 20px;
        font-size: 20px;
        color: #fff;
        letter-spacing: 2px;
    }
    @media (max-width:481px) {
        .hidden-xs{
            display: none;
        }
    }
    @media (max-width:641px) and (min-width: 480px){
        .hidden-s{
            display: none;
        }
    }
    .test-flex{
        display: flex;
        align-items: center;
        justify-content: space-around;
        background-color: #3987F3;
    }
    &::before{
        position: absolute;
        content: '';
        width: 100%;
        height: 5px;
        top: 0;
        background-color: #3987F3;
    }
    .overlay {
        width: 100%;
        height: 100%;
        position: absolute;
        background-color: rgba(0, 0, 0, 0.411);
        top: 0;
        z-index: 101;
        display: block;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        display: none;
        overflow: hidden;
    }
    .header-inner{
        .header-middle{
            display: flex;
            padding: 40px 0 23px 0;
            justify-content: space-between;
            align-items: center;
            .logo{
                display: flex;
                align-items: center;
                .logo-link{
                    margin-right: 30px;
                    img{
                        height: 55px;
                    }
                }
                h1{
                    font-family: Nunito Sans;
                    font-style: normal;
                    font-weight: 800;
                    font-size: 16px;
                    line-height: 22px;
                    color: #2A2A2A;
                    max-width: 450px;
                    a{
                        font-family: Nunito Sans;
                        font-style: normal;
                        font-weight: 600;
                        font-size: 12px;
                        line-height: 16px;
                        color: #3987F3;
                        margin-left: 10px;
                    }
                }
            }
            border-bottom: 1px dashed #d8d8d8;

            .languages{
                margin-top: 0;
                ul{
                    display: flex;
                    align-items: center;
                    margin-bottom: 0;
                    li{
                        display: block;
                        margin: 0 10px;
                        button{
                            font-family: Nunito Sans;
                            font-style: normal;
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 22px;
                            color: #696868;
                            opacity: 0.7;
                            transition: 0.2s;
                            border: none;
                            background-color: transparent;
                            &:hover{
                                color: #000;
                            }
                            &.active{
                                color: #000;
                                font-weight: bolder;
                            }
                        }
                    }
                }
            }

            .phone-number{
                a{
                    font-family: Nunito Sans;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 16px;
                    line-height: 22px;
                    color: #2A2A2A;
                    position: relative;
                    &::before{
                        position: absolute;
                        content: '';
                        width: 100%;
                        height: 100%;
                        left: -25px;
                        background-image: url('../../assets//icons/phone.png');
                        background-repeat: no-repeat;
                    }
                }
            }

            .dark-mode{
                button{
                    border: none;
                    border: 2px solid #DBDDE1;
                    box-sizing: border-box;
                    border-radius: 20px;
                    width: 47px;
                    height: 26px;
                    position: relative;
                    cursor: pointer;
                    &::before{
                        position: absolute;
                        content: '';
                        background: #757B87;
                        width: 18px;
                        height: 18px;
                        top: 2px;
                        left: 3px;
                        bottom: 0;
                        margin: absolute;
                        border-radius: 50%;
                        transition: 0.1s;
                    }
                    &.active {
                        &::before{
                            left: 22px;
                        }
                    }
                }
            }

            .log-searc{
                display: flex;
                align-items: center;
                .search{
                    margin-left: 27px;
                    background-color: transparent;
                    border: none;
                    cursor: pointer;
                }

                .user{
                    background-color: transparent;
                    border: none;
                    cursor: pointer;
                }

                .ant-drawer-content-wrapper{
                    width: 45% !important;
                }
            }

            .humb-btn{
                display: none;
            }
        }

        .header-bottom{
            border-bottom: 1px dashed #d8d8d8;
            padding: 0 30px 0 20px;


            .dropdown {
                margin: 0;
                padding: 0;
                list-style: none;
                background-color: transparent;
                display: flex;
                align-items: center;
                justify-content: space-between;

                .drop-link_name{
                    color: #000;
                    font-size: 18px;
                    &::before{
                        position: absolute;
                        content: '';
                        width: 14px;
                        height: 11px;
                        background-color: #3987F3;
                        clip-path: polygon(48% 40%, 0% 100%, 100% 100%);
                        top: 5px;
                        bottom: 0;
                        margin: auto;
                        right: -10px;
                        transform: rotate(180deg);
                    }

                    &.another{
                        &::before{
                            display: none;
                        }
                    }

                    &.activeTwo{
                        &::before{
                            display: none;
                        }

                        & +  .open-links{
                            display: none;
                        }
                    }
                }
            }
    
            .dropdown li {
                position: relative;
            }
    
            .dropdown li a {
                color: #ffffff;
                text-align: center;
                text-decoration: none;
                display: block;
                padding: 10px;
            }
    
            .dropdown li ul {
                position: absolute;
                top: 100%;
                margin: 0;
                padding: 0;
                list-style: none;
                display: none;
                line-height: normal;
                background: #E8F0FD;
            }
    
            .dropdown li ul li a {
                text-align: left;
                color: #000;
                font-size: 16px;
                padding: 10px;
                display: block;
                white-space: nowrap;
            }
    
            .dropdown li ul li a:hover {
                    color: #fff;
                    background: #40a9ff;
            }
    
            .dropdown li ul li ul {
                left: 100%;
                top: 0;
            }

            ul li:hover>ul {
                display: block;
            }

        }
    }
}
.search-component{
    position: fixed;
    overflow: hidden;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(78 76 76 / 95%);
    z-index: 1;
    display: none;
    &.active{
        display: block;
    }

    .input-search{
        margin: 70px auto 0 auto;
        display: block;
        padding: 10px;
        border: none;
        background-color: rgb(133, 131, 131);
        border-radius: 10px;
        color: #fff;
        outline: none;
        width: 70%;
    }

    .close-btn{
        position: absolute;
        top: 30px;
        right: 30px; 
        cursor: pointer;
        background-color: transparent;
        border: none;
        img{
            width: 25px;
            height: 25px;
        }
    }

    .result-cards{
        margin-top: 40px;
        margin-left: 50px;
        height: 700px;
        overflow: scroll;
        width: 70%;
        margin: 30px auto 0 auto;
        &::-webkit-scrollbar{
            display: none;
        }
        li.hide{
            display: none;
        }
        li.show{
            display: block;
        }
        li.no-result{
            color: #fff;
            text-align: center;
            img{
                width: 50px;
                color: #fff;
            }
            p{
                font-size: 20px;
            }
        }
        .search-li{
            list-style: inside;
            color: #fff;
            padding: 10px 0;
            .search-link{
                color: #fff;
                &:hover{
                    text-decoration: underline;
                }
            }   
        }
    }
}

.user-contact{
    border: none;
    display: flex;
    align-items: center;
    width: 40px;
    height: 40px;
    justify-content: center;
    border-radius: 50%;
    background-color: transparent;
    margin-right: 20px;
    position: relative;
    h1{
        font-size: 17px;
    }
    &:hover{
        span{
            display: block;
        }
    }
    span{
        position: absolute;
        font-size: 12px;
        width: 100%;
        left: -70px;
        bottom: -20px;
        width: 140px;
        border-radius: 10px;
        box-sizing: border-box;
        background-color: #3987F3;
        color: #fff;
        text-align: center;
        font-weight: bold;
        display: none;
    }
}


    .log-out{
        width: 30px;
        height: 30px;
        border: none;
        background-color: transparent;
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
}

.user-icon{
    width: 30px;
    height: 30px;
    object-fit: contain;
    position: relative;
}

.image-logo{
    margin-bottom: 30px;
    display: flex;
    width: 100%;
    justify-content: center;
    img{
        width: 55%;
        height: 100%;
        object-fit: contain;
    }
}

.drop-link_name.active{
    color: #3987F3 !important;
    position: relative;
    &::after{
        position: absolute;
        bottom: 0;
        width: 100%;
        background-color: #3987F3;
        content: '';
        height: 2px;
        left: 0;      
        bottom: -1px;                     
    }
}