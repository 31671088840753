@media screen and (max-width: 1250px) {
    .header {
        z-index: 1;

        .header-inner {
            .header-middle {
                display: flex;
                padding: 20px 0 20px 0;
                justify-content: space-between;
                align-items: center;

                .logo {
                    display: flex;
                    align-items: center;

                    .logo-link {
                        margin-right: 20px;
                    }

                    h1 {
                        font-size: 14px;
                        max-width: 300px;
                    }
                }

                .log-searc {
                    display: flex;
                    align-items: center;

                    .search {
                        margin-left: 27px; 
                    }
                }
            }

            .header-bottom {
                padding: 0;
                .dropdown{
                    li{
                        .drop-link_name{
                            font-size: 16px;
                        }
                    }
                }
            }
        }
    }
}



@media screen and (max-width: 1000px) {
    .header {
        .header-inner {
            .header-middle {
                padding: 33px 0 25px 0;
                .logo {
                    h1 {
                        font-size: 16px;
                        max-width: 450px;
                    }
                }
                .languages{
                    display: none;
                }
                .phone-number{
                    display: none;
                }
            }

            .header-bottom {
                .dropdown{
                    li{
                        .drop-link_name{
                            font-size: 14px;
                        }
                    }
                }
            }
        }
    }
}




@media screen and (max-width: 1000px) {
    .header {
        .header-inner {
            .header-middle {
                padding: 33px 0 25px 0;
                position: relative;

                .logo {
                    h1 {
                        font-size: 16px;
                        max-width: 450px;
                    }
                }

                .languages {
                    display: none;
                }
                .log-searc{
                    position: absolute;
                    right: 50px;
                }

                .phone-number {
                    display: none;
                }

                .humb-btn {
                    display: block;
                    .ant-drawer-content-wrapper {
                        width: 80% !important;
                    }
                }
            }

            .header-bottom {
                .dropdown{
                    display: none;
                }
            }
        }
    }
}


@media screen and (max-width: 700px) {
    .header {

        .header-top {
            display: none;
        }

        .header-inner {
            .header-middle {
                padding: 15px 0 10px 0;

                .logo {
                    width: 60%;
                    h1 {
                        display: none;
                    }

                    .logo-link {
                        img {
                            height: 100%;
                        }
                    }

                    .old-site{
                        display: none;
                    }
                }

                .log-searc{
                    .user{
                        margin-right: 20px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 400px) {
    .header{
        .header-inner{
            .header-middle{
                .logo{
                    .logo-link{
                        img{
                            width: 80px;
                            height: 45px;
                        }
                    }
                }   

                .log-searc{
                    .search{
                        margin-left: 15px;
                        img{
                            width: 20px;
                            height: 20px;
                        }
                    }
                    .user{
                        margin-left: 15px;
                        img{
                            width: 19px;
                            height: 24px;
                        }
                    }
                }

                .humb-btn{
                    .open-humb{
                        padding-left: 15px;
                    }
                }
            }
        }
    }
}


@media screen and (max-width: 800px) {
     .header {

         .header-inner {
             .header-middle {
                 padding: 15px 0 10px 0;
                 .dark-mode{
                     margin-left: 5px;
                 }

                 .log-searc{
                     margin: 0 10px;
                     .search{
                         margin-left: 15px;
                     }
                 }
             }
         }
     }
}

@media screen and (max-width: 900px) {
    
}