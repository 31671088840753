.title_main_tomchi {
  background-image: url("https://wsrc-assets.wsrc.uz/uploads/pages/pagecover.png");
  width: 100%;
  padding: 26px 0 91px;
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat;
  position: relative;
  min-height: 184px;
  display: flex;
  justify-content: center;
  align-items: center;
  h1 {
    font-weight: 600;
    font-size: 40px;
    font-family: Poppins;
    font-style: normal;
    line-height: 52px;
  }
}

.tomchi {
  display: flex;
  justify-content: space-between;
  padding: 40px 0;
}
.column_one {
  width: 20%;
  ul {
    width: 100%;
    background: #f6f6f7;
    border-radius: 10px;
    margin-bottom: 20px;
    h1 {
      color: black;
      font-size: 24px;
      padding: 10px 0;
    }
    li {
      font-size: 18px;
      width: 100%;
      a {
        width: 100%;
        padding: 8px 10px;
        display: inline-block;
        border-radius: 10px;
        font-family: "Montserrat", sans-serif;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        color: #282f38;
        &.active {
          background: #3987f3;
          color: #fff;
        }
      }
    }
    &.menyu{
      li{
        a{
          padding: 15px;
          border-bottom: 1px solid #e0e0e0;
        }
      }
    }
  }
  img {
    width: 100%;
  }
}
.column_two {
  width: 60%;
  padding: 0 40px;
  header {
    border-bottom: 1px solid #ccc;
    margin-bottom: 20px;
    padding-bottom: 24px;
    h1 {
      font-family: Poppins;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;
      color: #2d2d2d;
      position: relative;
      padding-left: 41px;
      &::before {
        position: absolute;
        content: "";
        width: 25px;
        height: 7px;
        background: #3987f3;
        border-radius: 10px;
        left: 0;
        bottom: 0;
        top: 0;
        margin: auto;
      }
    }
  }
}
.column_three {
  width: 20%;
  .block-holder {
    h2 {
      font-family: Montserrat;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 22px;
      color: #2d2d2d;
      margin-bottom: 24px;
      position: relative;
      padding-left: 45px;
      &::before {
        position: absolute;
        content: "";
        top: 0;
        bottom: 0;
        left: 0;
        background: #3987f3;
        border-radius: 10px;
        width: 25px;
        height: 7px;
        margin: auto;
      }
    }
  }
}
.card {
  max-height: 200px;
  padding: 10px;
  margin: 10px auto;
  border: 2px solid rgb(233, 228, 228);
  border-radius: 8px;
  box-sizing: border-box;
  a {
    img {
      width: 30%;
      height: 120px;
      object-fit: fill;
    }
    h3 {
      font-size: 18px;
      padding: 0 10px;
    }
  }

  &:hover h3 {
    color: rgb(82, 169, 175);
  }
}
.socials-block{
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
  .block{
    height: 150px;
    &:hover{
      background-color: #d9e0e9;
      transition: all .75s easy;
    }
    img:hover{
      transition: all .75s easy;
      -webkit-filter: invert(100%);
      filter: invert(100%);
    }
  }
}
.social-popup{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100% !important;
  z-index: 999;
  background: rgba(0, 0, 0, 0.28);
  .body{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    background: #fff;
    width: 320px;
    padding: 15px;
    a:hover{
      text-decoration: underline;
    }
    .close-button{
      position: absolute;
      top: -10px;
      right: -10px;
      font-size: 18px;
      font-weight: 700;
      display: block;
      padding: 0 6px;
      background: #fff;
      border: 1px solid red;
      cursor: pointer;
      border-radius: 100%;
      &:hover{
        color: #f00;
      }
    }
  }
}
